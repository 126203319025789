<template>
  <ion-page class="page scrollable bg-transparent pb-5">
    <ParagraphWithTitle class="mt-0" :title="p1.title" :body="p1.body" />

    <Breadcrumbs :breadcrumbs="ocArtBreadcrumbs" />
    <PageHeader class="mt-4" :title="sectionTitle" :subtitle="subtitle" invert />

    <img loading="lazy" src="/images/header_OC_art.png" class="mt-3 borders" />

    <section class="buttons mt-4 px-2">
      <TitleWithAction title="Featured Original Character Designs" class="mb-2" />
      <div class="text">
        We're so lucky to have incredible original character artists from around the world! Enjoy some featured art from
        our OC community! Submit your characters and art on the site to have a chance to be featured here!
      </div>
      <FanArtGrid class="mt-4" :fan-arts="fanArts" />
    </section>

    <section class="buttons mt-4 px-2">
      <TitleWithAction title="Explore more" class="mb-2" />
      <ButtonGrid
        :style="{ '--background': '#214163', textTransform: 'unset', '--border-radius': '20px' }"
        :buttons="links"
      />
    </section>
  </ion-page>
</template>

<script lang="ts" setup>
import Breadcrumbs from '@/shared/components/breadcrumbs.vue';
import ButtonGrid from '@/shared/components/button-grid.vue';
import TitleWithAction from '@/shared/components/title-with-action.vue';
import PageHeader from '@/shared/components/page-header.vue';
import FanArtGrid from '@/shared/components/fan-art-grid.vue';
import image from '../../../public/images/header_OC_art.png';
import { getFeaturedVisuals } from '@/shared/actions/characters';

const ocArtBreadcrumbs = ref([
  {
    label: 'Home',
    href: '/home',
  },
  {
    label: 'Original Character Art',
    href: '/fandom',
    isActive: true,
  },
]);

const {
  public: { baseCDNUrl },
} = useRuntimeConfig();

const picture = ref(`${baseCDNUrl}${image}`);
const sectionTitle = ref('Original Character Art');
const title = ref('Discover The Best Original Character (OC) Art and Artists - CharacterHub');
const description = ref(
  'Explore thousands of OC drawings and character designs! Browse artwork created by original character artists from all over the world.'
);
const pageTitle = ref('Original Character Art');
const allowCrawlers = ref(true);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);
const allowAiBots = ref(true);

const p1 = ref({
  title: 'Explore the Best Original Character Art!',
  body: `Explore hundreds of curated original character art and character designs! You'll find OC art made by talented
        artists from all over the world. New Featured Original Character Designs is added every week! Are you a character artist looking to get your art
        featured? Share your character designs on <a class="link" href="/">CharacterHub</a> for a chance to show up here!`,
});

const links = ref<any[]>([{ label: 'Original Characters', href: '/original-character' }]);
const subtitle = ref('Explore The Best Original Character Art On The Internet!');
const fanArts = ref<any[]>([]);

await useChAsyncData(async () => {
  const { results } = await getFeaturedVisuals(undefined, 1, 20);
  fanArts.value = results.map(({ image, character }: any) => ({
    visual_url: image.image,
    character,
    image,
  }));
  if (fanArts.value?.length > 0) {
    picture.value = fanArts.value[0].visual_url;
  }
  useChHead(pageTitle, title, description, url, picture, allowCrawlers, allowAiBots);
});
</script>

<style lang="sass" scoped>
.borders
  border-radius: 12px
h1
  margin: 2rem auto
h2
  font-weight: bold
  font-size: 1.25rem
article
  background: rgba(121, 121, 121, 0.2)
  padding: 1rem
  border-radius: 10px
.title
  font-size: 28px
  font-weight: bold
  margin-bottom: 3rem
  text-align: center
.fandom
  border-radius: 16px
  background: rgba(121, 121, 121, 0.2)
  padding: 0.4rem 1rem
  font-size: 0.8rem
  min-width: 100px
  text-align: center
.fanart
  object-fit: cover
  width: 100%
  height: 100%
.fan-art-card
  border-radius: 10px
  height: 300px
.art-link
  font-weight: bold
.art-caption
  text-align: center
</style>
